@charset 'UTF-8';

.js_bg_lazy_first, .js_bg_lazy{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.c-btn {
  display: block;
  text-align: center;
  span {
    display: inline-block;
  }
}

#wrap{
  margin-top: 102px;
  @include SP{
    margin-top: 65px;
  }
}

.box-title{
  padding-left: 50px;
  margin-bottom: 29px;
  @include SP{
    padding-left: 25px;
  }
  &__jp{
    @include font(38, 51px, -.06em, bold);
    margin-bottom: 5px;
    @include SP{
      @include font(20, 1.5);
    }
  }
  &__en{
    font-family: $ffRoboto;
    @include font(13, 15px, '', bold);
    position: relative;
    &:before{
      content: "";
      width: 40px;
      height: 1px;
      top: 0;
      bottom: 0;
      left: -50px;
      position: absolute;
      margin: auto;
      background: #000;
      @include SP{
        width: 16px;
        left: -25px;
      }
    }
  }
  &--white{
    color: #fff;
    .box-title__en{
      &:before{background: #fff;}
    }
  }
}