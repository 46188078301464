@charset 'UTF-8';
.footer{
  background: #EDFCFF;
  padding-top: 107px;
  padding-bottom: 30px;
  @include SP{
    padding-top: 50px;
  }
  &__logo{
    display: block;
    margin: auto;
    width: 290px;
    margin-bottom: 63px;
    @include SP{
      margin-bottom: 30px;
    }
  }
  .copyright{
    text-align: center;
    @include font(12, 14px);
  }
  .footer-box{
    @include flexbox;
    @include flex-wrap;
    margin-bottom: 35px;
    .footer-box-left{
      width: 300px;
      @include SP{
        width: 100%;
        margin-bottom: 20px;
      }
      .txt-01{
        @include font(14, 24px);
        .txt-map{
          @include font(22, 26px);
          text-decoration: underline;
          margin-left: 10px;
        }
        &.margin{
          margin-top: 12px;
          margin-bottom: 35px;
        }
      }
      .txt-02{
        @include font(22, 26px);
        .small{
          @include font(14)
        }
      }
    }
    .footer-box-right{
      width: calc(100% - 300px);
      padding-left: 80px;
      @include SP{
        width: 100%;
        padding-left: 0;
      }
    }
  }
  .footer-nav{
    @include flexbox;
    @include flex-wrap;
    margin-bottom: 25px;
    li{
      width: 40%;
      padding-right: 20px;
      @include font(14, 24px);
      margin-bottom: 10px;
      @include SP{
        @include font(12);
        @include IP5{
          @include font(10);
        }
      }
      a{
        position: relative;
        padding-left: 20px;
        display: inline-block;
        &:before{
          content: "";
          width: 8px;
          height: 8px;
          border-top: 2px solid #004295;
          border-right: 2px solid #004295;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          transform: rotate(45deg);
        }
      }
      &:nth-child(2n){
        width: 60%;
      }
    }
  }
  .footer-icon{
    @include flexbox;
    @include flex-wrap;
    @include SP{
      @include justify-content;
    }
    li ~ li{
      margin-left: 10px;
    }
  }
}