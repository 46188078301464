@charset 'UTF-8';

.header{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  z-index: 10;
  .header-container{
    padding: 46px 26px 16px; 
    width: 100%;
    max-width: 1400px;
    display: block;
    margin: auto;
    @include flexbox;
    @include flex-wrap;
    position: relative;
    @include SP{
      padding: 20px;
    }
  }
  @include SP{
    display: block;
    max-height: 66px;
    @include trans;
    &.active{
      height: 100vh;
      max-height: 100vh;
      overflow: auto;
    }
  }
  &__logo{
    width: 266px;
    display: block;
    @include SP{
      width: 200px;
    }
  }
  &__nav{
    width: calc(100% - 266px);
    text-align: right;
    font-size: 0;
    line-height: 0;
    @include SP{
      width: 100%;
      margin-top: 50px;
      display: none;
    }
    li{
      @include font(16, 19px, '', bold);
      display: inline-block;
      vertical-align: middle;
      a{
        @include trans;
        @include HOVER{
          &:hover{
            opacity: 1;
            color: #07569e;
          }
        }
      }
      @include SP{
        display: block;
        text-align: center;
        ~ li{
          margin-top: 30px;
        }
      }
      @include PC{
        ~ li{
          margin-left: 75px;
          @include max-screen(1300px){
            margin-left: 40px;
          }
          @include max-screen(1110px){
            margin-left: 20px;
          }
        }
      }
    }
  }

  .btn-hd{
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../img/common/btrn_rec2.jpg);
    color: #fff;
    text-align: right;
    padding-top: 10px;
    padding-right: 10px;
    width: 120px;
    height: 40px;
    display: block;
    @include SP{
      margin: auto;
    }
  }
}

.hamberger{
  position: absolute;
  width: 40px;
  height: 40px;
  top: 13px;
  right: 20px;
  &:before, &:after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 2px;
    background: #000;
    @include trans;
  }
  &:before{
    box-shadow: 0 -14px 0 #000, 0 14px 0 #000;
  }
  &.active{
    &:before, &:after{
      transform: rotate(45deg);
      box-shadow: 0 0 0 #000, 0 0 0 #000;
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
}